import React, { Component } from 'react';
import { CircleFlag } from 'react-circle-flags';

import { Collapse, Container } from '@material-ui/core';
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { Event, ExpandLess, ExpandMore } from '@material-ui/icons';
import { withStyles, WithStyles } from '@material-ui/styles';

import { chartDatesListStyles, flagIconHeight } from './ChartDatesListStyles';
import { ChartType, DateObject } from '../../service/chartApi';
import { DateService } from '../../service/dateUtilities';

interface ChartDatesListProps extends WithStyles<typeof chartDatesListStyles> {
  chartType: ChartType;
  drawerOpen: boolean;
  songDates: DateObject[];
};

interface ChartDatesListState {
  expanded: boolean;
};

const chartInformation = {
  [ChartType.ukChart]: {
    countryCode: 'gb',
    title: 'UK Official Singles Chart',
  },
  [ChartType.usChart]: {
    countryCode: 'us',
    title: 'Billboard Hot 100',
  },
};

class ChartDatesList extends Component<ChartDatesListProps, ChartDatesListState> {
  constructor(props: ChartDatesListProps) {
    super(props);

    this.state = { expanded: false };
  };

  dateService = new DateService();

  componentDidUpdate(previousProps: ChartDatesListProps) {
    const { drawerOpen: oldDrawerOpen } = previousProps;
    const { drawerOpen: newDrawerOpen } = this.props;

    if (newDrawerOpen !== oldDrawerOpen && !newDrawerOpen) {
      this.setState((state) => ({ expanded: false }));
    }
  };

  onExpandClick(): void {
    const { expanded } = this.state;
    this.setState((state) => ({ expanded: !expanded }));
  };

  render() {
    const { chartType, songDates } = this.props;
    const { expanded } = this.state;

    return (
      <Container>
        <List>
          <ListItem
            button
            key={`date-list-${chartType}`}
            onClick={this.onExpandClick.bind(this)}
          >
            <ListItemIcon>
              <CircleFlag
                countryCode={chartInformation[chartType].countryCode}
                height={flagIconHeight}
              />
            </ListItemIcon>
            <ListItemText>
              {chartInformation[chartType].title}
            </ListItemText>
            {expanded ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
        </List>
        <Collapse in={expanded} timeout='auto' unmountOnExit>
          <List dense={true}>
            {songDates.map(dateObject => {
              const oldDate = new Date(dateObject.previous_date);
              const newDate = new Date(dateObject.date);

              const oldDateString = this.dateService.getDateString(
                this.dateService.getNextDay(oldDate),
                true
              );
              const newDateString = this.dateService.getDateString(newDate, true);

              const rangeText = `${oldDateString} to ${newDateString}`;
              return (
                <ListItem key={rangeText}>
                  <ListItemIcon>
                    <Event />
                  </ListItemIcon>
                  <ListItemText secondary={rangeText} />
                </ListItem>
              );
            })}
          </List>
        </Collapse>
      </Container>
    );
  };
};

export default withStyles(chartDatesListStyles)(ChartDatesList);
