import React, { Component } from 'react';

import { Box, Container, Divider, Drawer } from '@material-ui/core';
import { Button, Grid, IconButton } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { Add, ChevronLeft } from '@material-ui/icons';
import { withStyles, WithStyles } from '@material-ui/styles';

import { songDrawerStyles } from './SongDrawerStyles';
import ChartDatesList from '../ChartDatesList/ChartDatesList';
import SongVideo from '../SongVideo/SongVideo';
import { chartInformation, ChartType, SongItem, UpdateSongVideoResponse } from '../../service/chartApi';
import { DateService } from '../../service/dateUtilities';
import SongVideoTextField from '../SongVideoTextField/SongVideoTextField';
import { VideoAlert } from '../VideoAlert/VideoAlert';

enum snackbarSeverity {
  success = 'success',
  info = 'info',
  warning = 'warning',
  error = 'error'
};

interface SongDrawerProps extends WithStyles<typeof songDrawerStyles> {
  addVideoUrl: (newUrl: string) => Promise<UpdateSongVideoResponse>;
  chartType: ChartType;
  deleteVideoUrl: (videoUrl: string) => Promise<UpdateSongVideoResponse>;
  idToken: string;
  isOpen: boolean;
  removeSelectedSong: () => void;
  selectedSong?: SongItem;
  updateVideoUrl: (oldVideoId: string, newUrl: string) => Promise<UpdateSongVideoResponse>;
};
interface SongDrawerState {
  addVideoButtonClicked: boolean;
  snackbarMessage: string;
  snackbarOpen: boolean;
  snackbarSeverity: snackbarSeverity;
  videoPlaying: boolean;
};

const millisecondsInWeek = 1000 * 60 * 60 * 24 * 7;
const defaultUrl = 'https://www.youtube.com/watch?v=video_id';
const defaultSongItem: SongItem = {
  artist: 'Song Artist',
  hot100: [{ date: '1939-01-08', previous_date: '1939-01-01' }],
  id: 'Song ID',
  title: 'Song Title',
  ukofficial: [],
  video_ids: [],
  year: 1999,
};

class SongDrawer extends Component<SongDrawerProps, SongDrawerState> {
  constructor(props: SongDrawerProps) {
    super(props);

    this.state = {
      addVideoButtonClicked: false,
      snackbarMessage: 'Video URL added!',
      snackbarOpen: false,
      snackbarSeverity: snackbarSeverity.success,
      videoPlaying: false
    };
  };

  dateService = new DateService();

  componentDidUpdate(previousProps: SongDrawerProps) {
    const { isOpen: oldIsOpen } = previousProps;
    const { isOpen: newIsOpen } = this.props;

    if (newIsOpen !== oldIsOpen && !newIsOpen) {
      this.setState((state) => ({
        addVideoButtonClicked: false,
        videoPlaying: false,
      }));
    }
  };

  calculateWeeksBetween(newerDateString: string, olderDateString: string): number {
    const newerDate = new Date(newerDateString);
    const olderDate = new Date(olderDateString);
    return Math.ceil((newerDate.getTime() - olderDate.getTime()) / millisecondsInWeek);
  };

  getWeeksAtNumberOne(): number {
    const { chartType, selectedSong = defaultSongItem } = this.props;

    return selectedSong[chartType].reduce((accumulator, dateObject) => {
      const { date, previous_date } = dateObject;
      return accumulator + this.calculateWeeksBetween(date, previous_date);
  
}, 0);
  };

  handleSnackbarClose() {
    this.setState((state) => ({ snackbarOpen: false }));
  };

  onPlayClick(): void {
    this.setState((state) => ({ videoPlaying: true }));
  };

  onPauseClick(): void {
    this.setState((state) => ({ videoPlaying: false }));
  };

  onAddVideoClick(): void {
    this.setState((state) => ({ addVideoButtonClicked: true }));
  };

  displaySnackbar(statusCode: number, successMessage: string, errorMessage: string): void {
    if (statusCode === 200) {
      this.setState((state) => ({
        snackbarMessage: successMessage,
        snackbarOpen: true,
        snackbarSeverity: snackbarSeverity.success,
      }));
    } else {
      this.setState((state) => ({
        snackbarMessage: errorMessage,
        snackbarOpen: true,
        snackbarSeverity: snackbarSeverity.error,
      }));
    };
  };

  async onSaveNewVideoClick(newVideoUrl: string): Promise<UpdateSongVideoResponse> {
    // TODO: Need to close the text field AFTER the last state change
    const { addVideoUrl } = this.props;

    const response = await addVideoUrl(newVideoUrl);

    if (response.statusCode === 200) {
      this.setState((state) => ({
        addVideoButtonClicked: false,
      }));
    };

    this.displaySnackbar(
      response.statusCode,
      'Video URL added!',
      'Could not add video URL!'
    );

    return response;
  };

  async onDeleteVideoUrl(savedUrl: string): Promise<UpdateSongVideoResponse> {
    const { deleteVideoUrl } = this.props;

    const response = await deleteVideoUrl(savedUrl);

    this.displaySnackbar(
      response.statusCode,
      'Video URL deleted!',
      'Could not delete video URL!'
    );

    return response;
  };

  async onUpdateVideoUrl(oldUrl: string, newUrl: string): Promise<UpdateSongVideoResponse> {
    const { updateVideoUrl } = this.props;

    const response = await updateVideoUrl(oldUrl, newUrl);

    this.displaySnackbar(
      response.statusCode,
      'Video URL updated!',
      'Could not update video URL!'
    );

    return response;
  };

  onCancelNewVideoClick(): void {
    this.setState((state) => ({ addVideoButtonClicked: false }));
  };

  renderDrawerHeader() {
    const { removeSelectedSong } = this.props;
    const { selectedSong = defaultSongItem } = this.props;
    return (
      <Box m={2}>
        <Grid alignItems='center' container justify='space-between'>
          <Grid item key='songDrawerTitle'>
            <Typography variant='h5'>{selectedSong.year}</Typography>
          </Grid>
          <Grid item key='songDrawerCloseButton'>
            <IconButton onClick={removeSelectedSong} size='small'>
              <ChevronLeft />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
    );
  };

  renderAddVideoButton() {
    const { addVideoButtonClicked } = this.state;
    return (
      addVideoButtonClicked
        ? <SongVideoTextField
            onAddUrl={this.onSaveNewVideoClick.bind(this)}
            onDeleteUrl={this.onCancelNewVideoClick.bind(this)}
            savedUrl={defaultUrl}
          />
        : <Box textAlign='center' width={1}>
            <Button
              onClick={this.onAddVideoClick.bind(this)}
              startIcon={<Add />}>Add video
            </Button>
          </Box>
    );
  };

  render() {
    const {
      chartType,
      classes,
      idToken,
      isOpen,
      selectedSong = defaultSongItem,
    } = this.props;
    const { artist, title, video_ids = [] } = selectedSong;
    const {
      snackbarMessage,
      snackbarOpen,
      snackbarSeverity,
      videoPlaying
    } = this.state;

    const weeksAtNumberOne = this.getWeeksAtNumberOne();
    const weeksPlural = weeksAtNumberOne === 1 ? '' : 's';
    return (
      <Drawer 
        anchor='left'
        classes={{ paper: classes.drawerPaper }}
        open={isOpen}
        variant='persistent'
      >
        {this.renderDrawerHeader()}
        <Container>
          <Container className={classes.songMainInformation}>
            <Typography variant='body1'>{title}</Typography>
            <Typography variant='body1'>{artist}</Typography>
            <Typography variant='body1'>
            {weeksAtNumberOne} week{weeksPlural} at number one
            </Typography>
          </Container>
          <Divider />
          {video_ids.length > 0 &&
             <SongVideo
               idToken={idToken}
               onDeleteVideoUrl={this.onDeleteVideoUrl.bind(this)}
               onPlayClick={this.onPlayClick.bind(this)}
               onPauseClick={this.onPauseClick.bind(this)}
               onUpdateVideoUrl={this.onUpdateVideoUrl.bind(this)}
               videoIds={video_ids}
               videoPlaying={videoPlaying}
             />
          }

          {idToken.length > 0 && this.renderAddVideoButton()}
          <Divider />
          <ChartDatesList
            chartType={chartType}
            drawerOpen={isOpen}
            songDates={selectedSong[chartType]}
          />
          {chartInformation.filter(
             chartElement => chartElement.chartType !== chartType &&
               selectedSong[chartElement.chartType] !== undefined &&
               selectedSong[chartElement.chartType].length > 0
           ).map(chartElement => {
             return (
               <ChartDatesList
                 key={`dates-${chartElement.chartType}`}
                 chartType={chartElement.chartType}
                 drawerOpen={isOpen}
                 songDates={selectedSong[chartElement.chartType]}
               />
             );
           })
          }
        </Container>
        <VideoAlert
          isOpen={snackbarOpen}
          message={snackbarMessage}
          onClose={this.handleSnackbarClose.bind(this)}
          severity={snackbarSeverity}
        />
      </Drawer>
    );
  };
}

export default withStyles(songDrawerStyles)(SongDrawer);
