import React, { Component } from 'react';

import { CircularProgress, Grid } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { withStyles, WithStyles } from '@material-ui/styles';

import { loadingSpinnerStyles } from './LoadingSpinnerStyles';

interface LoadingSpinnerProps extends WithStyles<typeof loadingSpinnerStyles>{
  message: string;
};

interface LoadingSpinnerState {};

class LoadingSpinner extends Component<LoadingSpinnerProps, LoadingSpinnerState> {
  render() {
    const { message } = this.props;
    const { containerClass, itemClass } = this.props.classes;

    return (
      <Grid
        alignItems='center'
        className={containerClass}
        container
        direction='column'
        justify='center'
      >
        <Grid
          className={itemClass}
          item
          key='api-spinner'
        >
          <CircularProgress
            size={80}
            variant='indeterminate'
          />
        </Grid>
        <Grid
          className={itemClass}
          item
          key='api-message'
        >
          <Typography
            align='center'
            color='textPrimary'
            variant='h5'
          >
            {message}
          </Typography>
        </Grid>
      </Grid>
    );
  };
};

export default withStyles(loadingSpinnerStyles)(LoadingSpinner);
