import React, { Component } from 'react';

import { Button, Box, IconButton } from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';

import { DateService } from '../../service/dateUtilities';
import DateModal from '../DateModal/DateModal';

interface DatePickerProps {
  chartDate: Date;
  isLoading: boolean;
  updateChartDate: (newDate: Date) => void;
};

interface DatePickerState {
  dateModalOpen: boolean;
};

export class DatePicker extends Component<DatePickerProps, DatePickerState> {
  constructor(props: DatePickerProps) {
    super(props);
    this.state = { dateModalOpen: false };
  }

  dateService = new DateService();

  handleClickDate() {
    this.setState((state) => ({ dateModalOpen: state.dateModalOpen ? false : true }));
  };

  handleClickNextDate() {
    const { chartDate, updateChartDate } = this.props;
    const nextDate = this.dateService.getNextDay(chartDate);
    updateChartDate(nextDate);
  };

  handleClickPreviousDate() {
    const { chartDate, updateChartDate } = this.props;
    const previousDate = this.dateService.getPreviousDay(chartDate);
    updateChartDate(previousDate);
  };

  handleCloseDateModal() {
    this.setState((state) => ({ dateModalOpen: false}));
  };

  handleModalDateSelection(monthIndex: number, dayNumber: number) {
    const { chartDate, updateChartDate } = this.props;
    this.handleCloseDateModal();

    const newChartDate = new Date(2020, monthIndex, dayNumber);
    const newChartKey = this.dateService.getKey(newChartDate);
    const currentChartKey = this.dateService.getKey(chartDate);
    if (newChartKey !== currentChartKey) {
      updateChartDate(newChartDate);
    }
  };

  render() {
    const { chartDate, isLoading } = this.props;
    const { dateModalOpen } = this.state;

    return (
      <Box>
        <IconButton
          disabled={isLoading}
          onClick={this.handleClickPreviousDate.bind(this)}
          size='small'
        >
          <ChevronLeft />
        </IconButton>
        <Button
          disabled={isLoading}
          onClick={this.handleClickDate.bind(this)}
        >
          {this.dateService.getDateString(chartDate, false)}
        </Button>
        <IconButton
          disabled={isLoading}
          onClick={this.handleClickNextDate.bind(this)}
          size='small'
        >
          <ChevronRight />
        </IconButton>
        <DateModal
          modalOpen={dateModalOpen}
          onClose={this.handleCloseDateModal.bind(this)}
          onDateSelection={this.handleModalDateSelection.bind(this)}
        />
      </Box>
    );
  }
};
