interface StageConfiguration {
  readonly host: string;
};

interface StagesType {
  [key: string]: StageConfiguration;
};

const stages: StagesType = {
  development: { host: 'http://localhost:3000' },
  production: { host: 'https://api.jukeboxdaily.com' },
  test: { host: 'http://localhost:3000' },
};

const STAGE: string = process.env.NODE_ENV || 'production';

export const { [STAGE]: config } = stages;
