import clsx from 'clsx';
import React, { Component } from 'react';

import { Box, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { ChartApiService, ChartType, SongItem, UpdateSongVideoResponse } from '../../service/chartApi';
import { DateService } from '../../service/dateUtilities';
import ChartGrid from '../ChartGrid/ChartGrid';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import SongDrawer from '../SongDrawer/SongDrawer';
import TopBar from '../TopBar/TopBar';
import { appStyles } from './AppStyles';

interface AppProps {
  classes: Record<string, any>;
};

interface AppState {
  chartDate: Date;
  chartType: ChartType;
  idToken: string;
  isLoading: boolean;
  selectedSong?: SongItem;
  songs: SongItem[];
  songSelected: boolean;
};

class App extends Component<AppProps, AppState> {
  constructor(props: AppProps) {
    super(props);
    const chartDate = new Date();
    this.state = {
      chartDate,
      chartType: ChartType.usChart,
      idToken: '',
      isLoading: false,
      selectedSong: undefined,
      songs: [],
      songSelected: false,
    };
  };

  chartApiService = new ChartApiService();
  dateService = new DateService();

  async componentDidMount() {
    const { chartDate } = this.state;
    await this.handleDateSelected(new Date(chartDate.getTime()));
  };

  async handleDateSelected(newChartDate: Date) {
    const { chartDate: previousChartDate, chartType } = this.state;
    if (newChartDate !== previousChartDate) {
      this.setState((state) => ({
        chartDate: newChartDate,
        isLoading: true,
        selectedSong: undefined,
        songSelected: false,
      }));

      const dateKey = this.dateService.getKey(newChartDate);
      const songs = await this.chartApiService.getChartData(chartType, dateKey);
      if (this.chartApiService.isValidChartData(songs)) {
        this.setState((state) => ({ isLoading: false, songs: songs as SongItem[] }));
      } else {
        this.setState((state) => ({ isLoading: false }));
      }
    };
  };

  async handleChartTypeSelected(newChartType: ChartType) {
    const { chartDate, chartType: previousChartType } = this.state;
    if (newChartType !== previousChartType) {
      this.setState((state) => ({
        chartType: newChartType,
        isLoading: true,
        selectedSong: undefined,
        songSelected: false,
      }));

      const dateKey = this.dateService.getKey(chartDate);
      const songs = await this.chartApiService.getChartData(newChartType, dateKey);
      if (this.chartApiService.isValidChartData(songs)) {
        this.setState((state) => ({ isLoading: false, songs: songs as SongItem[] }));
      } else {
        this.setState((state) => ({ isLoading: false }));
      }
    };
  };

  getNewVideoUrls(newVideoUrl: string): string[] {
    const { selectedSong } = this.state;

    let video_ids: string[] = [];

    if (selectedSong !== undefined) {
      video_ids = selectedSong.video_ids !== undefined
        ? selectedSong.video_ids
        : [];
    };

    let videoUrls = video_ids.map((videoId: string) => `https://www.youtube.com/watch?v=${videoId}`);

    const newVideoIndex = videoUrls.indexOf(newVideoUrl);

    if (newVideoIndex !== -1) {
      videoUrls[newVideoIndex] = newVideoUrl;
    } else {
      videoUrls.push(newVideoUrl);
    };

    return videoUrls;
  };

  async updateSongUrls(songId: string, newVideoUrls: string[]): Promise<UpdateSongVideoResponse> {
    const { idToken } = this.state;

    let updateResponse = await this.chartApiService.updateSongVideos(
      songId,
      newVideoUrls,
      idToken
    );

    if (updateResponse.statusCode === 200) {
      const { message: newVideoIds } = updateResponse;

      let newVideoIdsSplit: string[] = [];

      if (newVideoIds !== '') {
        // There were some video IDs remaining
        newVideoIdsSplit = newVideoIds.split(',');
      };

      let newSongs = [...this.state.songs];
      const songIndex = newSongs.findIndex((songItem: SongItem) => songItem.id === songId);
      const newSongItem = {
        ...newSongs[songIndex],
        video_ids: newVideoIdsSplit,
      };
      newSongs[songIndex] = newSongItem;

      this.setState((state) => ({
        selectedSong: newSongItem,
        songs: newSongs,
      }));
    };

    return updateResponse;
  };

  getCurrentSongVideoUrls(selectedSong: SongItem): string[] {
    const { video_ids = [] } = selectedSong;
    return video_ids.map((videoId: string) => `https://www.youtube.com/watch?v=${videoId}`);
  };

  async handleSongVideoDeletion(videoUrl: string): Promise<UpdateSongVideoResponse> {
    const { selectedSong } = this.state;
    const songId = selectedSong !== undefined ? selectedSong.id : undefined;

    let updateResponse;

    if (selectedSong !== undefined && songId !== undefined) {
      const currentVideoUrls = this.getCurrentSongVideoUrls(selectedSong);
      const newVideoUrls = currentVideoUrls.filter((currentVideoUrl: string) => currentVideoUrl !== videoUrl);
      updateResponse = this.updateSongUrls(songId, newVideoUrls);
    } else {
      updateResponse = { statusCode: 500, message: 'Song not selected.' }
    }

    return updateResponse;
  };

  async handleSongVideoAddition(videoUrl: string): Promise<UpdateSongVideoResponse> {
    const { selectedSong } = this.state;
    const songId = selectedSong !== undefined ? selectedSong.id : undefined;

    let updateResponse;

    if (selectedSong !== undefined && songId !== undefined) {
      let videoUrls = this.getCurrentSongVideoUrls(selectedSong);
      videoUrls.push(videoUrl);
      updateResponse = this.updateSongUrls(songId, videoUrls);
    } else {
      updateResponse = { statusCode: 500, message: 'Song not selected.' }
    }

    return updateResponse;
  };

  async handleSongVideoUpdated(oldVideoUrl: string, newVideoUrl: string): Promise<UpdateSongVideoResponse> {
    const { selectedSong } = this.state;
    const songId = selectedSong !== undefined ? selectedSong.id : undefined;

    let updateResponse;

    if (selectedSong !== undefined && songId !== undefined) {
      let videoUrls = this.getCurrentSongVideoUrls(selectedSong);
      const videoIndex = videoUrls.indexOf(oldVideoUrl);
      videoUrls[videoIndex] = newVideoUrl;
      updateResponse = this.updateSongUrls(songId, videoUrls);
    } else {
      updateResponse = { statusCode: 500, message: 'Song not selected.' }
    }

    return updateResponse;
  };

  deselectedSong(): void {
    this.setState((state) => ({ songSelected: false }));
  };

  updateSelectedSong(song: SongItem): void {
    this.setState((state) => ({
      selectedSong: song,
      songSelected: true,
    }));
  };

  updateIdToken(idToken: string): void {
    this.setState((state) => ({ idToken }));
  };

  render() {
    const { classes } = this.props;
    const {
      chartDate,
      chartType,
      idToken,
      isLoading,
      selectedSong,
      songs,
      songSelected,
    } = this.state;

    return (
      <Box bgcolor='background.default' height='100%'>
        <TopBar
          chartDate={chartDate}
          chartType={chartType}
          handleChartTypeSelected={this.handleChartTypeSelected.bind(this)}
          idToken={idToken}
          isLoading={isLoading}
          songs={songs}
          updateChartDate={this.handleDateSelected.bind(this)}
          updateIdToken={this.updateIdToken.bind(this)}
        />
        <SongDrawer
          addVideoUrl={this.handleSongVideoAddition.bind(this)}
          deleteVideoUrl={this.handleSongVideoDeletion.bind(this)}
          chartType={chartType}
          idToken={idToken}
          isOpen={songSelected}
          removeSelectedSong={this.deselectedSong.bind(this)}
          selectedSong={selectedSong}
          updateVideoUrl={this.handleSongVideoUpdated.bind(this)}
        />
        <main className={clsx(classes.content, {[classes.contentShift]: songSelected})}>
          <Grid
            className={classes.mainPageElement}
            container
            justify='center'
          >
            {isLoading
              ? <LoadingSpinner message='Retrieving song data...'/>
              : <ChartGrid
                  songs={songs}
                  songSelected={songSelected}
                  updateSelectedSong={this.updateSelectedSong.bind(this)}
                />}
          </Grid>
        </main>
      </Box>
    );
  }
}

export default withStyles(appStyles)(App);
