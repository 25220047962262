export class DateService {

  getDateString(date: Date, includeYear: boolean): string {
    const monthString = date.toLocaleString('default', { month: 'long', timeZone: 'UTC' });
    const dayOfMonth = date.getUTCDate();
    const ordinalSuffix = this.getOrdinalSuffix(dayOfMonth);
    const yearString = includeYear ? ` ${date.getUTCFullYear()}` : '';

    return `${dayOfMonth}${ordinalSuffix} ${monthString}${yearString}`
  };

  getKey(date: Date): string {
    const month = this.padOutput(date.getUTCMonth() + 1);
    const day = this.padOutput(date.getUTCDate());
    return `${month}-${day}`;
  }

  getOrdinalSuffix(inputDate: number): string {
    const modulatedUnits = inputDate % 10;
    const modulatedTens = Math.floor((inputDate / 10) % 10);

    if ([1, 2, 3].includes(modulatedUnits) && modulatedTens !== 1) {
      if (modulatedUnits === 1) {
        return 'st';
      } else if (modulatedUnits === 2) {
        return 'nd';
      } else {
        return 'rd';
      }
    } else {
      return 'th';
    }
  }

  getToday(): Date {
    return new Date();
  }

  getNextDay(day: Date): Date {
    const nextDay = new Date(day);
    nextDay.setDate(nextDay.getDate() + 1);
    return nextDay;
  }

  getPreviousDay(day: Date): Date {
    const previousDay = new Date(day);
    previousDay.setDate(previousDay.getDate() - 1);
    return previousDay;
  }

  padOutput(number: number): string {
    return number.toString().padStart(2, '0');
  }
}
