import React, { Component, MouseEvent } from 'react';

import { IconButton, ListItemIcon, Menu, MenuItem } from '@material-ui/core';
import { QueueMusic } from '@material-ui/icons';
import { withStyles, WithStyles } from '@material-ui/styles';

import { SongItem } from '../../service/chartApi';
import { playlistButtonStyles } from './PlaylistButtonStyles';

interface PlaylistButtonProps extends WithStyles<typeof playlistButtonStyles> {
  allSongs: SongItem[];
  isLoading: boolean;
};

interface PlaylistButtonState {
  playlistButtonMenuAnchor: HTMLButtonElement | null;
};

interface PlaylistMenuItem {
  firstYear: number;
  lastYear: number;
  videoIds: string[];
};

interface VideoYearObject {
  videoId: string;
  year: number;
};

class PlaylistButton extends Component<PlaylistButtonProps, PlaylistButtonState> {

  constructor(props: PlaylistButtonProps) {
    super(props);
    this.state = { playlistButtonMenuAnchor: null };
  };

  // Limitation - the anonymous playlist is capped at 50 videos.
  // To work around this the songs are split between multiple playlists.
  handleClickPlaylist(event: MouseEvent<HTMLButtonElement>): void {
    const { currentTarget = null } = event;
    this.setState((state) => ({
	  playlistButtonMenuAnchor: state.playlistButtonMenuAnchor === null ? currentTarget : null }));
  };

  getPlaylistMenuItems(): PlaylistMenuItem[] {
    const { allSongs } = this.props;

    const yearIds = allSongs
      .map(song => {
        const { video_ids = [] } = song;
        return video_ids.map(videoId =>  {
          return { 'videoId': videoId, 'year': song.year };
        })
      })
      .reduce((accumulator, item) => [...accumulator, ...item], [] as VideoYearObject[]);

	console.log(`yearIds: ${JSON.stringify(yearIds)}`);
    let playlists: PlaylistMenuItem[] = [];

    if (yearIds.length > 0) {
      const numberOfPlaylists = Math.ceil(yearIds.length / 50);
      const songsPerPlaylist = Math.ceil(yearIds.length / numberOfPlaylists);

      for (let i = 0; i < numberOfPlaylists; i++) {
        const startIndex = i * songsPerPlaylist;
        const endIndex = (i + 1) * songsPerPlaylist;

        const playlistMembers = yearIds.slice(startIndex, endIndex)
        playlists.push({
          firstYear: playlistMembers[0].year,
          lastYear: playlistMembers[playlistMembers.length - 1].year,
          videoIds: playlistMembers.map(playlistMember => playlistMember.videoId),
        });
      };
    };

    return playlists;
  };

  handleClosePlaylistButtonMenu(): void {
    this.setState((state) => ({ playlistButtonMenuAnchor: null }));
  };

  handlePlaylistMenuItemClick(playlistVideoIds: string[]): void {
    const playlistUrl = `https://www.youtube.com/watch_videos?video_ids=${playlistVideoIds.join(',')}`;
    window.open(playlistUrl,'_blank');
    this.handleClosePlaylistButtonMenu();
  };


  render() {
    const { isLoading } = this.props;
    const { playlistButtonMenuAnchor } = this.state;

    return (
      <div>
        <IconButton
          disabled={isLoading}
          onClick={this.handleClickPlaylist.bind(this)}
          size='small'
        >
          <QueueMusic/>
        </IconButton>
        <Menu
          anchorEl={playlistButtonMenuAnchor}
          anchorOrigin={{vertical: 'bottom', horizontal: 'center' }}
          getContentAnchorEl={ null }
          onClose={this.handleClosePlaylistButtonMenu.bind(this)}
          open={Boolean(playlistButtonMenuAnchor)}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          {this.getPlaylistMenuItems().map(playlistMenuItem => {
            return (
              <MenuItem
                key={`playlist-${playlistMenuItem.firstYear}-${playlistMenuItem.lastYear}`}
                onClick={() => this.handlePlaylistMenuItemClick(playlistMenuItem.videoIds)}
              >
                <ListItemIcon>
                  <QueueMusic/>
                </ListItemIcon>
                {`${playlistMenuItem.firstYear} - ${playlistMenuItem.lastYear}`}
              </MenuItem>
            );
          })}
        </Menu>
      </div>
    );
  }
};

export default withStyles(playlistButtonStyles)(PlaylistButton);
