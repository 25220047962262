import React, { Component } from 'react';

import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { Button, TextField, Typography } from '@material-ui/core';
import { withStyles, WithStyles } from '@material-ui/styles';

import { AuthenticationService } from '../../service/authentication';
import { logInModalStyles } from './LogInModalStyles';

type FormFields = 'email' | 'password';

interface LogInModalProps extends WithStyles<typeof logInModalStyles> {
  modalOpen: boolean;
  onClose: () => void;
  updateIdToken: (idToken: string) => void;
};

interface LogInModalState {
  email: string;
  emailDirty: boolean;
  emailValid: boolean;
  errorMessage: string;
  password: string;
  passwordDirty: boolean;
  passwordValid: boolean;
};

class LogInModal extends Component<LogInModalProps, LogInModalState> {

  constructor(props: LogInModalProps) {
    super(props);
    this.state = {
      email: '',
      emailDirty: false,
      emailValid: false,
      errorMessage: '',
      password: '',
      passwordDirty: false,
      passwordValid: false,
    };
  };

  authenticationService = new AuthenticationService();

  handleClickLogIn() {};

  clearAndClose() {
    const { onClose } = this.props;

    this.setState((state) => ({
      email: '',
      emailDirty: false,
      emailValid: false,
      errorMessage: '',
      password: '',
      passwordDirty: false,
      passwordValid: false,
    }));

    onClose();
  };

  async onSubmitClick() {
    const { email, password } = this.state;

    this.setState((state) => ({ errorMessage: '' }));

    await this.authenticationService.signIn(
      email,
      password,
      this.handleSuccessfulLogIn.bind(this),
      this.handleFailedLogIn.bind(this)
    );
  };

  handleSuccessfulLogIn(idToken: string) {
    const { updateIdToken } = this.props;

    updateIdToken(idToken);
    this.clearAndClose();
  };

  handleFailedLogIn(errorMessage: string) {
    this.setState((state) => ({ errorMessage }));
  };

  updateEmail(event: React.ChangeEvent<HTMLInputElement>) {
    const { value: email } = event.target;

    this.setState((state) => ({
      email,
      emailDirty: true,
      emailValid: this.validateEmail(email),
    }));
  };

  updatePassword(event: React.ChangeEvent<HTMLInputElement>) {
    const { value: password } = event.target;

    this.setState((state) => ({
      password,
      passwordDirty: true,
      passwordValid: this.validatePassword(password),
    }));
  };

  validateEmail(email: string): boolean {
    const pattern = /^[\w.-]{1,64}@[a-zA-Z0-9.-]{1,255}\.[a-zA-Z]{2,4}$/;
    return pattern.test(email);
  };

  validatePassword(password: string): boolean {
    return password !== '';
  };

  render() {
    const { modalOpen, onClose } = this.props;
    const {
      emailDirty,
      emailValid,
      errorMessage,
      passwordDirty,
      passwordValid
    } = this.state;

    return (
      <Dialog
        open={modalOpen}
        onClose={onClose}
        onEnter={this.handleClickLogIn.bind(this)}
      >
        <DialogTitle id='login-modal-title'>Sign In</DialogTitle>
        <DialogContent>
          {
            errorMessage !== ''
            ? <Typography color='error'>{errorMessage}</Typography>
            : null
          }
          <TextField
            error={emailDirty && !emailValid}
            fullWidth
            id='login-email-address-field'
            label='Email Address'
            name='email'
            margin='dense'
            onChange={this.updateEmail.bind(this)}
            required
            type='email'
          />
          <TextField
            error={passwordDirty && !passwordValid}
            fullWidth
            id='login-password-field'
            label='Password'
            margin='dense'
            name='password'
            onChange={this.updatePassword.bind(this)}
            required
            type='password'
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.clearAndClose.bind(this)}>
            Cancel
          </Button>
          <Button
            disabled={!emailValid || !passwordValid}
            onClick={this.onSubmitClick.bind(this)}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
};

export default withStyles(logInModalStyles)(LogInModal);
