import React, { Component } from 'react';

import { Card, CardHeader, CardMedia } from '@material-ui/core';
import { Avatar, IconButton } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import { withStyles, WithStyles } from '@material-ui/styles';

import { SongItem } from '../../service/chartApi';
import { chartCardStyles } from './ChartCardStyles';

interface ChartCardProps extends WithStyles<typeof chartCardStyles>{
  makeSelection: () => void;
  song: SongItem;
};

interface ChartCardState {};

class ChartCard extends Component<ChartCardProps, ChartCardState> {
  getImageUrl(): string | undefined {
    const { video_ids } = this.props.song;

    let imageUrl = undefined;

    if (video_ids !== undefined && video_ids.length > 0) {
      imageUrl = `https://img.youtube.com/vi/${video_ids[0]}/mqdefault.jpg`;
    }

    return imageUrl;
  };

  truncateString(inputString: string, maximumLength: number): string {
    let outputString = inputString;

    if (inputString.length > maximumLength) {
      outputString = inputString.slice(0, Math.max(maximumLength - 3, 0)).trim() + '...';
    }

    return outputString;
  };

  getArtistString(): string {
    const { artist } = this.props.song;
    return this.truncateString(artist, 16);
  };

  getTitleString(): string {
    const { title } = this.props.song;
    return this.truncateString(title, 30);
  };

  renderDetailsButton() {
    const { makeSelection } = this.props;

    return (
      <IconButton onClick={makeSelection} size='small'>
        <InfoOutlined />
      </IconButton>
    );
  };

  renderYearAvatar() {
    const { year } = this.props.song;

    return (
      <Avatar variant='rounded'>
        {year}
      </Avatar>
    );
  };

  render() {
    const { header, media, root } = this.props.classes;
    const { title, video_ids = undefined } = this.props.song;

    return (
      <Card className={root} variant='elevation'>
        <CardHeader
          action={this.renderDetailsButton()}
          avatar={this.renderYearAvatar()}
          className={header}
          subheader={this.getArtistString()}
          title={this.getTitleString()}
        />
        {video_ids !== undefined && video_ids.length > 0 &&
          <CardMedia
            className={media}
            component='img'
            height='140'
            image={this.getImageUrl()}
            title={title}
          />
        }
      </Card>
    );
  };
}

export default withStyles(chartCardStyles)(ChartCard);
