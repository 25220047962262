export const songDrawerWidth = 360;

export const songDrawerStyles = {
  drawerPaper: {
    paddingTop: 64,
    width: songDrawerWidth,
  },

  songMainInformation: {
    paddingBottom: 10,
    paddingTop: 10,
  },
};
