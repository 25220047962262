import axios from 'axios';
import { config } from '../config';

export interface DateObject {
  date: string;
  previous_date: string;
};

export enum ChartType {
  ukChart = 'ukofficial',
  usChart = 'hot100',
}

export interface RawSongItem {
  artist: string;
  hot100: DateObject[];
  id: string;
  title: string;
  ukofficial: DateObject[];
  video_ids?: string[];
};

export interface SongItem extends RawSongItem {
  year: number;
};

export interface UpdateSongVideoResponse {
  statusCode: number;
  message: string;
}

export interface ChartInformation {
  chartType: ChartType;
  countryCode: string;
  title: string;
};

export const chartInformation: ChartInformation[] = [
  {
    chartType: ChartType.ukChart,
    countryCode: 'gb',
    title: 'UK Official Singles Chart',
  },
  {
    chartType: ChartType.usChart,
    countryCode: 'us',
    title: 'Billboard Hot 100',
  },
];

export class ChartApiService {
  compareSongItemYears(songOne: SongItem, songTwo: SongItem) {
    return songOne.year - songTwo.year
  };

  deriveSongYears(rawSongs: RawSongItem[], chartType: ChartType, dayKey: string): SongItem[] {
    return rawSongs.map(rawSong => {
      const years = rawSong[chartType].reduce((years, dateObject) => {
        const startDate = new Date(dateObject.previous_date);
        const endDate = new Date(dateObject.date);

        [startDate, endDate].forEach((date: Date) => {
          const dateYear = date.getFullYear();
          const keyDayUsingYear = new Date(`${dateYear}-${dayKey}`);
          if (keyDayUsingYear > startDate &&
            keyDayUsingYear <= endDate && 
            !years.includes(dateYear)
          ) {
            years.push(dateYear);
          }
        });

        return years;
      }, [] as number[]);

      return years.map(year => { return { ...rawSong, year }});
    }).reduce((flattenedSongs, songsArray) => {
      return flattenedSongs.concat(songsArray)
    }, [] as SongItem[]).sort(this.compareSongItemYears);
  };

  async getChartData(chartType: ChartType, dayKey: string): Promise<SongItem[] | string> {

    const requestData = { params: { chart: chartType } };

    try {
      const response =  await axios.get(
        `${config.host}/days/${dayKey}`,
        requestData
      );
      return this.deriveSongYears(response.data.songs, chartType, dayKey);
    } catch (error) {
      return error.message;
    }
  };

  isSongItem(songItem: SongItem): boolean {
    return 'artist' in songItem && 'title' in songItem;
  };

  isValidChartData(responseData: SongItem[] | string): boolean {
    return responseData instanceof Array && responseData.every(this.isSongItem);
  };

  async updateSongVideos(
    songId: string,
    videoUrls: string[],
    idToken: string
  ) : Promise<UpdateSongVideoResponse> {
    try {
      const requestParameters = { videoUrls };
      const requestConfig = {
        headers: { Authorization: `Bearer ${idToken}` }
      };

      const response = await axios.put(
        `${config.host}/songs/${songId}/videos`,
        requestParameters,
        requestConfig
      );

      return { statusCode: response.status, message: response.data };
    } catch (error) {
      return { statusCode: error.status, message: error.message };
    }
  }
};
