import React, { Component, MouseEvent } from 'react';

import { AppBar, Box, IconButton, Toolbar, Typography } from '@material-ui/core';
import { Menu, MenuItem } from '@material-ui/core';
import { AccountCircle, ExitToApp } from '@material-ui/icons';
import { withStyles, WithStyles } from '@material-ui/styles';

import { topBarStyles } from './TopBarStyles';
import { ChartType, SongItem } from '../../service/chartApi';
import ChartPicker from '../ChartPicker/ChartPicker';
import { DatePicker } from '../DatePicker/DatePicker';
import PlaylistButton from '../PlaylistButton/PlaylistButton';
import LogInModal from '../LogInModal/LogInModal';

interface TopBarProps extends WithStyles<typeof topBarStyles> {
  chartDate: Date;
  chartType: ChartType;
  handleChartTypeSelected: (chartType: ChartType) => void;
  idToken: string;
  isLoading: boolean;
  songs: SongItem[];
  updateChartDate: (newDate: Date) => void;
  updateIdToken: (idToken: string) => void;
};

interface TopBarState {
  accountMenuAnchor: HTMLButtonElement | null;
  logInModalOpen: boolean;
};

class TopBar extends Component<TopBarProps, TopBarState> {

  constructor(props: TopBarProps) {
    super(props);
    this.state = {
      accountMenuAnchor: null,
      logInModalOpen: false,
    };
  };

  handleClickLogIn() {
    this.setState((state) => ({ logInModalOpen: !state.logInModalOpen }));
  };

  handleCloseLogInModal(): void {
    this.setState((state) => ({ logInModalOpen: false}));
  };

  handleClickAccount(event: MouseEvent<HTMLButtonElement>): void {
    const { currentTarget = null } = event;

    this.setState((state) => ({ accountMenuAnchor: currentTarget }));
  };

  handleCloseAccountMenu() {
    this.setState((state) => ({ accountMenuAnchor: null }));
  };

  handleLogout() {
    const { updateIdToken } = this.props;

    this.handleCloseAccountMenu();
    updateIdToken('');
  };

  renderLogInButton() {
    return (
      <IconButton
        edge='end'
        onClick={this.handleClickLogIn.bind(this)}
      >
        <ExitToApp />
      </IconButton>
    );
  };

  renderAccountButton() {
    const { accountMenuAnchor } = this.state;

    return (
      <div>
        <IconButton onClick={this.handleClickAccount.bind(this)} edge='end'>
          <AccountCircle />
        </IconButton>
        <Menu
          anchorEl={accountMenuAnchor}
          onClose={this.handleCloseAccountMenu.bind(this)}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={Boolean(accountMenuAnchor)}>
          <MenuItem onClick={this.handleLogout.bind(this)}>Logout</MenuItem>
        </Menu>
      </div>
    );
  };

  render() {
    const {
      chartDate,
      chartType,
      classes,
      handleChartTypeSelected,
      idToken,
      isLoading,
      songs,
      updateChartDate,
      updateIdToken
    } = this.props;

    const { logInModalOpen } = this.state;
    return (
      <AppBar className={classes.appBar} color='primary' position='sticky'>
        <Toolbar>
          <Box className={classes.title}>
            <Typography
              align='center'
              color='textPrimary'
              variant='h5'
            >
              JukeboxDaily
            </Typography>
          </Box>
          <ChartPicker
            chartType={chartType}
            handleChartTypeSelected={handleChartTypeSelected}
          />
          <DatePicker
            chartDate={chartDate}
            isLoading={isLoading}
            updateChartDate={updateChartDate}
          />
          <PlaylistButton
            allSongs={songs}
            isLoading={isLoading}
          />
          <div className={classes.grow}/>
          {
            idToken === ''
            ? this.renderLogInButton()
            : this.renderAccountButton()
          }
          <LogInModal
            modalOpen={logInModalOpen}
            onClose={this.handleCloseLogInModal.bind(this)}
            updateIdToken={updateIdToken}
          />
        </Toolbar>
      </AppBar>
    );
  }
}

export default withStyles(topBarStyles)(TopBar);
