import React, { Component } from 'react';

import { Button, IconButton } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { Grid, GridList, GridListTile } from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import { withStyles, WithStyles } from '@material-ui/styles';

import { dateModalStyles } from './DateModalStyles';

const styles = { paper: dateModalStyles };

interface DateModalProps extends WithStyles<typeof styles>{
  modalOpen: boolean;
  onClose: () => void; 
  onDateSelection: (monthNumber: number, dayNumber: number) => void;
};

interface DateModalState {
  dayNumber: number;
  isMonthView: boolean;
  monthIndex: number;
};

const monthInformation = Array.from(Array(12).keys()).map(month => {
  const lastDay = new Date(2020, month + 1, 0);
  return { shortMonthName: lastDay.toLocaleString('default', {month: 'short'}), monthLength: lastDay.getDate() };
});

class DateModal extends Component<DateModalProps, DateModalState> {
  constructor(props: DateModalProps) {
    super(props);
    this.state = { dayNumber: 0, isMonthView: true, monthIndex: 0 };
  };

  componentDidMount() {
    this.setState((state) => ({ isMonthView: true }));
  };

  getMonthDays(monthIndex: number): number[] {
    const monthLength = monthInformation[monthIndex].monthLength;
    return Array.from(Array(monthLength + 1).keys()).slice(1);
  };

  onCancelClick() {
    const { onClose } = this.props;
    onClose();
  };

  onDayClick(dayNumber: number) {
    const { onDateSelection } = this.props;
    const { monthIndex } = this.state;
    onDateSelection(monthIndex, dayNumber);
  };

  onEnterModal() {
    this.setState((state) => ({ isMonthView: true }));
  };

  onLastMonthClick(oldMonthIndex: number) {
    const rawIndex = (oldMonthIndex - 1) % 12;
    const monthIndex = rawIndex < 0 ? rawIndex + 12: rawIndex;
    this.setState((state) => ({ monthIndex }));
  };

  onMonthClick(monthIndex: number) {
    this.setState((state) => ({
      isMonthView: false,
      monthIndex,
    }));
  };

  onMonthReturnClick() {
    this.setState((state) => ({ isMonthView: true }));
  };

  onNextMonthClick(oldMonthIndex: number) {
    const monthIndex = (oldMonthIndex + 1) % 12;
    this.setState((state) => ({ monthIndex }));
  };

  onTodayClick() {
    const { onDateSelection } = this.props;
    const today = new Date();
    onDateSelection(today.getMonth(), today.getDate());
  };

  toggleView() {
    const { isMonthView } = this.state;
    this.setState((state) => ({ isMonthView: !isMonthView }));
  };

  renderMonthView() {
    return (
      <DialogContent>
        <GridList cellHeight={40} cols={4}>
          {monthInformation.map((monthObject, monthIndex) => (
            <GridListTile key={monthObject.shortMonthName}>
              <Button
                onClick={this.onMonthClick.bind(this, monthIndex)}
              >
                {monthObject.shortMonthName}
              </Button>
            </GridListTile>
          ))}
        </GridList>
      </DialogContent>
    );
  };

  renderDayView() {
    const { monthIndex } = this.state;
    const monthDays = this.getMonthDays(monthIndex);

    return (
      <DialogContent>
        <Grid alignItems='center' container direction='row' justify='center'>
          <Grid key='date-modal-last-month' item>
            <IconButton onClick={this.onLastMonthClick.bind(this, monthIndex)}>
              <ChevronLeft />
            </IconButton>
          </Grid>
          <Grid key='date-modal-month-back' item>
            <Button onClick={this.onMonthReturnClick.bind(this)}>
              {monthInformation[monthIndex].shortMonthName}
            </Button>
          </Grid>
          <Grid key='date-modal-next-month' item>
            <IconButton onClick={this.onNextMonthClick.bind(this, monthIndex)}>
              <ChevronRight />
            </IconButton>
          </Grid>
        </Grid>
        <GridList cellHeight={40} cols={7}>
          {monthDays.map((monthDay) => (
            <GridListTile key={monthDay}>
              <Button onClick={this.onDayClick.bind(this, monthDay)}>
                {monthDay}
              </Button>
            </GridListTile>
          ))}
        </GridList>
      </DialogContent>
    );
  };

  render() {
    const { modalOpen, onClose } = this.props;
    const { isMonthView } = this.state;

    return (
      <Dialog
        open={modalOpen}
        onClose={onClose}
        onEnter={this.onEnterModal.bind(this)}
      >
        <DialogTitle id='date-modal-title'>Select date</DialogTitle>
        {isMonthView ? this.renderMonthView() : this.renderDayView()}
        <DialogActions>
          <Button onClick={this.onCancelClick.bind(this)}>
            Cancel
          </Button>
          <Button onClick={this.onTodayClick.bind(this)}>
            Today
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
};

export default withStyles(styles)(DateModal);
