import React, { Component, MouseEvent } from 'react';
import { CircleFlag } from 'react-circle-flags';

import { IconButton, ListItemIcon, Menu, MenuItem } from '@material-ui/core';
import { withStyles, WithStyles } from '@material-ui/styles';

import { chartPickerStyles, pickerFlagHeight } from './ChartPickerStyles';
import { chartInformation, ChartType } from '../../service/chartApi';

interface ChartPickerProps extends WithStyles<typeof chartPickerStyles> {
  chartType: ChartType;
  handleChartTypeSelected: (chartType: ChartType) => void;
}

interface ChartPickerState {
  chartPickerMenuAnchor: HTMLButtonElement | null;
};

class ChartPicker extends Component<ChartPickerProps, ChartPickerState> {

  constructor(props: ChartPickerProps) {
    super(props);
    this.state = { chartPickerMenuAnchor: null };
  };

  handleClickButton(event: MouseEvent<HTMLButtonElement>): void {
    const { currentTarget = null } = event;

    this.setState((state) => ({
	  chartPickerMenuAnchor: state.chartPickerMenuAnchor === null ? currentTarget : null }));
  };

  handleCloseChartPickerMenu(): void {
    this.setState((state) => ({ chartPickerMenuAnchor: null }));
  };

  handleChartItemClick(chartType: ChartType): void {
    const { handleChartTypeSelected } = this.props;

	this.handleCloseChartPickerMenu();
    handleChartTypeSelected(chartType);
  }

  render() {
    const { chartType } = this.props;
    const { chartPickerMenuAnchor } = this.state;

    const chartProperties = chartInformation.find(chartElement => chartElement.chartType === chartType) || chartInformation[0];

    return (
      <div>
        <IconButton onClick={this.handleClickButton.bind(this)}>
          <CircleFlag
            countryCode={chartProperties.countryCode}
            height={pickerFlagHeight}
          />
        </IconButton>
        <Menu
          anchorEl={chartPickerMenuAnchor}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          getContentAnchorEl={null}
          onClose={this.handleCloseChartPickerMenu.bind(this)}
          open={Boolean(chartPickerMenuAnchor)}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          {chartInformation.map(chartElement => {
            return (
              <MenuItem
                key={`chartMenu-${chartElement.chartType}`}
                onClick={() => this.handleChartItemClick(chartElement.chartType)}
              >
                <ListItemIcon>
                  <CircleFlag
                    countryCode={chartElement.countryCode}
                    height={pickerFlagHeight}
                  />
                </ListItemIcon>
                {chartElement.title}
              </MenuItem>
            );
          })}
        </Menu>
      </div>
    );
  };
};

export default withStyles(chartPickerStyles)(ChartPicker);
