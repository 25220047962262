export const chartCardStyles = {
  header: { height: '60px' },
  media: {
    height: '90px',
    width: 'auto',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  root: {
    height: '200px',
    margin: '5px',
  },
};
