import { AuthenticationDetails, CognitoUserPool, CognitoUser } from 'amazon-cognito-identity-js';


export class AuthenticationService {
  async signIn(
    username: string,
    password: string,
    successCallback: (idToken: string) => void,
    failureCallback: (errorMessage: string) => void
  ) {
    const credentials = new AuthenticationDetails({
      Username: username,
      Password: password,
    });

    const userPool = new CognitoUserPool({
      ClientId: '4ic3klk6f3qf5af8apo23pnalu',
      UserPoolId: 'us-east-1_dskea3lG3',
    });

    const cognitoUser = new CognitoUser({
      Username: username,
      Pool: userPool,
    });

    await cognitoUser.authenticateUser(credentials, {
      onSuccess: (result) => {
        const idTokenJwt = result.getIdToken().getJwtToken();
        successCallback(idTokenJwt);
      },
      onFailure: (error) => {
        failureCallback(error.message);
      },
    });
  };
};
