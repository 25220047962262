import React, { Component, createRef } from 'react';

import { GridList, GridListTile } from '@material-ui/core';
import { withStyles, WithStyles } from '@material-ui/styles';

import { SongItem } from '../../service/chartApi';
import ChartCard from '../ChartCard/ChartCard';
import { songDrawerWidth } from '../SongDrawer/SongDrawerStyles';
import { chartGridStyles } from './ChartGridStyles';

interface ChartGridProps extends WithStyles<typeof chartGridStyles>{
  songs: SongItem[];
  songSelected: boolean;
  updateSelectedSong: (song: SongItem) => void;
};

interface ChartGridState {
  gridColumns: number;
  width: number;
};

class ChartGrid extends Component<ChartGridProps, ChartGridState> {
  constructor(props: ChartGridProps) {
    super(props);
    this.state = {
      gridColumns: this.getNumberOfColumns(window.innerWidth),
      width: window.innerWidth,
    };
    this.updateNumberOfColumns = this.updateNumberOfColumns.bind(this);
  };

  chartGridRef = createRef<HTMLUListElement>();

  componentDidMount() {
    this.updateNumberOfColumns();
    window.addEventListener('resize', this.updateNumberOfColumns);
  };

  componentDidUpdate(prevProps: ChartGridProps, prevState: ChartGridState) {
    const { width: oldWidth } = prevState;
    const { songSelected } = this.props;

    const newWidth = this.getWidth(songSelected, window.innerWidth);

    if (newWidth !== oldWidth) {
      this.updateNumberOfColumns();
    }
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateNumberOfColumns);
  };

  getWidth(songSelected: boolean, width: number): number {
    return songSelected ? width - songDrawerWidth : width;
  };

  getNumberOfColumns(width: number) {
    const minCardWidth = 240;

    return Math.max(Math.floor(width / minCardWidth), 1);
  };

  updateNumberOfColumns() {
    const { songSelected } = this.props;
    const { gridColumns, width: oldWidth } = this.state;
    const width = this.getWidth(songSelected, window.innerWidth);
    const newColumns = this.getNumberOfColumns(width);

    if (newColumns !== gridColumns) {
      this.setState((state) => ({ gridColumns: newColumns, width }));
    } else if (oldWidth !== width) {
      this.setState((state) => ({ width }));
    }
  };

  render() {
    const { classes, songs, updateSelectedSong } = this.props;
    const { gridColumns } = this.state;

    return (
      <div>
        <GridList cellHeight='auto' cols={gridColumns} ref={this.chartGridRef}>
          {songs.map(song => {
            const { id, year } = song;
            const songKey = `${id}-${year}`;

            return (
              <GridListTile
                className={classes.chartTile}
                cols={1}
                key={songKey}
                rows={1}
              >
                <ChartCard
                  makeSelection={updateSelectedSong.bind(this, song)}
                  song={song}
                />
              </GridListTile>
            );
          })}
        </GridList>
      </div>
    );
  };
}

export default withStyles(chartGridStyles)(ChartGrid);
