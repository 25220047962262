import React, { Component } from 'react';

import { Snackbar } from '@material-ui/core';
import { SnackbarOrigin } from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

const snackBarAnchor: SnackbarOrigin = { horizontal: 'left', vertical: 'bottom' };

interface VideoAlertProps extends AlertProps {
  isOpen: boolean;
  message: string;
  onClose: () => void;
};

export class VideoAlert extends Component<VideoAlertProps> {
  render() {
    const { isOpen, message, onClose, severity } = this.props;

    return (
      <Snackbar
        anchorOrigin={snackBarAnchor}
        autoHideDuration={5000}
        open={isOpen}
        onClose={onClose}
      >
        <MuiAlert elevation={6} variant='filled' severity={severity}>
          {message}
        </MuiAlert>
      </Snackbar>
    );
  };
};
