import React, { Component } from 'react';
import ReactPlayer from 'react-player';

import { Container } from '@material-ui/core';
import { withStyles, WithStyles } from '@material-ui/styles';

import { songVideoStyles } from './SongVideoStyles';
import SongVideoTextField from '../SongVideoTextField/SongVideoTextField';
import { UpdateSongVideoResponse } from '../../service/chartApi';

interface SongVideoProps extends WithStyles<typeof songVideoStyles> {
  idToken: string;
  onDeleteVideoUrl: (videoUrl: string) => Promise<UpdateSongVideoResponse>;
  onPlayClick: () => void;
  onPauseClick: () => void;
  onUpdateVideoUrl: (oldVideoUrl: string, newUrl: string) => Promise<UpdateSongVideoResponse>;
  videoIds: string[];
  videoPlaying: boolean;
};

interface SongVideoState {};

class SongVideo extends Component<SongVideoProps, SongVideoState> {
  getVideoUrlFromId(videoId: string): string {
    return `https://www.youtube.com/watch?v=${videoId}`;
  };

  getPlayerUrls(): string | string[] {
    const { videoIds } = this.props;
    
    const videoUrls = videoIds.map((videoId: string) => this.getVideoUrlFromId(videoId));

    return (videoUrls.length === 1 ? videoUrls[0] : videoUrls);
  };

  render() {
    const {
      classes,
      idToken,
      onDeleteVideoUrl,
      onPlayClick,
      onPauseClick,
      onUpdateVideoUrl,
      videoPlaying,
      videoIds,
    } = this.props;

    return (
      <Container>
        <ReactPlayer
          className={classes.embeddedPlayer}
          height='auto'
          onPlay={onPlayClick}
          onPause={onPauseClick}
          playing={videoPlaying}
          url={this.getPlayerUrls()}
          width='100%'
        />
        {idToken.length > 0 && videoIds.map((videoId: string) => {
           return (
             <SongVideoTextField
               key={`song-text-field-${videoId}`}
               onDeleteUrl={onDeleteVideoUrl}
               onUpdateUrl={onUpdateVideoUrl}
               savedUrl={this.getVideoUrlFromId(videoId)}
             />
           );
        })}
      </Container>
    );
  };
};

export default withStyles(songVideoStyles)(SongVideo);
