export const topBarStyles = {
  appBar: {
    width: '100%',
    zIndex: 1400,
  },
  grow: {
    flexGrow: 1,
  },
  title: {
    paddingRight: 20,
  },
};
