import React, { Component } from 'react';

import { Box, TextField } from '@material-ui/core';
import { InputAdornment, IconButton, Tooltip } from '@material-ui/core';
import { Clear, Delete, Done, Edit, HelpOutline } from '@material-ui/icons';
import { withStyles, WithStyles } from '@material-ui/styles';

import { UpdateSongVideoResponse } from '../../service/chartApi';
import { songVideoTextFieldStyles } from './SongVideoTextFieldStyles';

type AddVideoFunction = (newVideoUrl: string) => Promise<UpdateSongVideoResponse>;
type CancelVideoFunction = () => void;
type DeleteVideoFunction = (videoUrl: string) => Promise<UpdateSongVideoResponse>;
type UpdateVideoFunction = (oldVideoId: string, newVideoUrl: string) => Promise<UpdateSongVideoResponse>;

interface SongVideoTextFieldProps extends WithStyles<typeof songVideoTextFieldStyles>{
  onAddUrl?: (newVideoUrl: string) => Promise<UpdateSongVideoResponse>;
  onDeleteUrl: CancelVideoFunction | DeleteVideoFunction;
  onUpdateUrl?: (oldVideoId: string, newVideoUrl: string) => Promise<UpdateSongVideoResponse>;
  savedUrl: string;
};

interface SongVideoTextFieldState {
  currentUrl: string;
  textFieldDisabled: boolean;
  urlDirty: boolean;
  urlUpdating: boolean;
  urlValid: boolean;
};

class SongVideoTextField extends Component<SongVideoTextFieldProps, SongVideoTextFieldState> {
  constructor(props: SongVideoTextFieldProps) {
    super(props);
    const { savedUrl } = this.props;

    this.state = {
      currentUrl: savedUrl,
      textFieldDisabled: true,
      urlDirty: false,
      urlUpdating: false,
      urlValid: true,
    };
  };

  componentDidUpdate(previousProps: SongVideoTextFieldProps) {
    // This is to detect when the selected song updates
    const { savedUrl: newSavedUrl } = this.props;
    const { savedUrl: oldSavedUrl } = previousProps;

    if (newSavedUrl !== oldSavedUrl) {
      this.setState((state) => ({
        currentUrl: newSavedUrl,
        textFieldDisabled: true,
        urlDirty: false,
        urlUpdating: false,
        urlValid: true,
      }));
    }
  };

  async handleDoneClick() {
    const { onAddUrl, onUpdateUrl, savedUrl } = this.props;
    const { currentUrl } = this.state;

    this.setState((state) => ({ urlUpdating: true }));

    if (onUpdateUrl !== undefined) {
      const updateResponse = await onUpdateUrl(savedUrl, currentUrl);

      // Only update state if this component persists (when updating)
      if (updateResponse.statusCode === 200) {
        this.setState((state) => ({
          textFieldDisabled: true,
          urlDirty: false,
          urlUpdating: false,
          urlValid: true,
        }));
      } else {
        this.setState((state) => ({
          urlUpdating: false,
          urlValid: false,
        }));
      }
    } else if (onAddUrl !== undefined) {
      await onAddUrl(currentUrl);
    }

  };

  handleCancelClick() {
    const { savedUrl } = this.props;

    this.setState((state) => ({
      currentUrl: savedUrl,
      textFieldDisabled: true,
      urlDirty: false,
      urlValid: true,
    }));
  };

  handleEditClick() {
    this.setState((state) => ({ textFieldDisabled: false }));
  };

  handleDeleteClick() {
    const { onDeleteUrl, savedUrl } = this.props;
    onDeleteUrl(savedUrl);
  };

  updateCurrentUrl(event: React.ChangeEvent<HTMLInputElement>) {
    const { value: newUrl } = event.target;

    this.setState((state) => ({
      currentUrl: newUrl,
      urlDirty: true,
      urlValid: this.validateUrl(newUrl),
    }));
  };

  validateUrl(urlString: string): boolean {
    let urlValid = true;

    try {
      const url = new URL(urlString);

      if (url.hostname === 'youtu.be') {
        // e.g.: https://youtu.be/ABC123
        urlValid = url.pathname.length > 1;
      } else if (url.hostname === 'www.youtube.com') {
        if (url.pathname.startsWith('/watch')) {
          // e.g.: https://www.youtube.com/watch?v=ABC123
          urlValid = url.searchParams.get('v') !== null;
        } else if (!url.pathname.startsWith('/embed/') && !url.pathname.startsWith('/v/')) {
          // e.g. neither: https://www.youtube.com/watch/ABC123
          //      nor:     https://www.youtube.com/embed/ABC123
          urlValid = false;
        } else {
          const pathBits = url.pathname.split('/')

          if (pathBits.length < 3) {
            urlValid = false;
          } else if (pathBits[2].length === 0) {
            urlValid = false;
          }
        }
      } else {
        urlValid = false;
      }
    } catch (error) {
      urlValid = false;
    }

    return urlValid;
  };

  renderAdornmentTooltip() {
    return (
      <div>
        https://youtu.be/ABC123 <br />
        https://www.youtube.com/watch?v=ABC123<br />
        https://www.youtube.com/embed/ABC123<br />
        https://www.youtube.com/v/ABC123
      </div>
    );
  };

  renderTextFieldHelp() {
    return (
      <Tooltip title={this.renderAdornmentTooltip()} arrow>
        <InputAdornment position='end'>
          <IconButton size='small'>
            <HelpOutline />
          </IconButton>
        </InputAdornment>
      </Tooltip>
    );
  };

  renderEditDeleteButton() {
    const { urlUpdating } = this.state;

    return (
      <Box p={1} display='flex'>
        <Box flexShrink={1}>
          <IconButton
            disabled={urlUpdating}
            onClick={this.handleEditClick.bind(this)}
            size='small'
          >
            <Edit />
          </IconButton>
        </Box>
        <Box flexShrink={0}>
          <IconButton
            disabled={urlUpdating}
            onClick={this.handleDeleteClick.bind(this)}
            size='small'
          >
            <Delete />
          </IconButton>
        </Box>
      </Box>
    );
  };

  renderDoneCancelButtons() {
    const { savedUrl } = this.props;
    const { currentUrl, urlDirty, urlUpdating, urlValid } = this.state;

    const urlUnchanged = currentUrl === savedUrl;

    return (
      <Box p={1} display='flex'>
        <Box flexShrink={1}>
          <IconButton
            disabled={urlUnchanged || urlUpdating || (!urlValid && urlDirty)}
            onClick={this.handleDoneClick.bind(this)}
            size='small'
          >
            <Done />
          </IconButton>
        </Box>
        <Box flexShrink={0}>
          <IconButton
            disabled={urlUpdating}
            onClick={this.handleCancelClick.bind(this)}
            size='small'
          >
            <Clear />
          </IconButton>
        </Box>
      </Box>
    );
  };

  render() {
    const { classes } = this.props;
    const {
      currentUrl,
      textFieldDisabled,
      urlDirty,
      urlValid
    } = this.state;

    return (
      <Box className={classes.grow} display='flex' alignItems='center'>
        <Box p={1} width='100%'>
          <TextField
            InputProps={{endAdornment: this.renderTextFieldHelp()}}
            disabled={textFieldDisabled}
            error={urlDirty && !urlValid}
            fullWidth
            id='song-video-url-field'
            name='videoUrl'
            margin='dense'
            onChange={this.updateCurrentUrl.bind(this)}
            value={currentUrl}
            type='url'
          />
        </Box>
        { textFieldDisabled ?
            this.renderEditDeleteButton() :
            this.renderDoneCancelButtons()
        }
      </Box>
    );
  };
};

export default withStyles(songVideoTextFieldStyles)(SongVideoTextField);
